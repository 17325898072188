import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Terms = props => {
  return (
    <Layout>
      <SEO title="Terms " />

      <div className="main">
        <div className="container">
          <div className="section_posting">
            <div className="mask_logo">
              {" "}
              +Add<strong>Friends</strong>
            </div>
            <div className="box">
              <h1>Terms and Conditions</h1>
              <div className="row">
                <p>
                  Please read these Terms of Service ("Terms", "Terms of
                  Service") carefully before using our website (the "Service")
                  operated by AddFriends.com ("us", "we", or "our"). Your
                  ("you","your","Subscriber") <strong>access</strong> to and use
                  of the Service is conditioned upon your acceptance of and
                  compliance with these Terms. These Terms apply to all
                  visitors, users and others who wish to access or use the
                  Service. Third party platforms used beyond the Service have
                  Terms and Conditions of their own that you must review and
                  agree upon separately. You recognize that the Service exists
                  solely as a conduit between Performers and Subscribers to
                  provide and restrict access to their private media and
                  information which represent the Performer. The Service is an
                  entertainment service. You should be cautious whenever
                  communicating with persons unknown to you, including our
                  Performers. The Service is <strong>absolutely not</strong> to
                  be used for arranging physical meetings of any sort what so
                  ever. All Performers, surfers, and Subscribers functioning
                  outside of our Terms are responsible for their own actions.
                  Both Performers and Subscribers alike are subject to a
                  permanent ban if found in violation of our terms.
                  <strong>
                    By accessing or using the Service you agree to be bound by
                    these Terms. If you disagree with any part of the terms
                    defined on this page then you do not have permission to
                    access the Service.
                  </strong>
                </p>
              </div>
            </div>
            <div className="box">
              <h2>What We Do</h2>
              <p>
                The Service provides <strong>access</strong> to a Creator's
                private library of media in exchange for a value defined by the
                Creator or person.
              </p>
            </div>
            <div className="box">
              <h2>What You Do</h2>
              <p>
                Contribute to the Creator with your membership fee then login
                and enjoy. Direct messaging is available for all Creators and we
                encourage you to engage with them!
              </p>
            </div>
            <div className="box">
              <h2>Approval of Adult Content</h2>
              <p>
                The Service provides access to some performers who produce
                age-restricted materials. If Subscriber is under the age of 18
                years, or under the age of majority in the location from where
                accessing the Service then the Subscriber does not have
                authorization or permission to enter or access any of its
                materials. If the Subscriber is over the age of 18 years and
                over the age of majority in the location from where accessing
                this site by entering the website Subscriber hereby agrees to
                comply with these terms and conditions and is granted use of the
                Service at our discretion.
              </p>
            </div>
            <div className="box">
              <h2>Subscriptions</h2>
              <p>
                Our Service is billed on a subscription basis
                ("Subscription(s)"). You will be billed in advance on a one-time
                or recurring and periodic basis ("Billing Cycle"). Billing
                cycles are set either on a daily, monthly, multi-monthly, or
                annual basis, depending on the type of subscription plan you
                select when purchasing a Subscription. The Contract is closed as
                soon as the order is submitted. At the end of each Billing
                Cycle, your Subscription will automatically renew under the
                exact same conditions unless you cancel it or we cancel it or
                you are notified of a price adjustment otherwise. You may cancel
                your Subscription renewal either through our billing companies
                (Epoch.com,SegPay.com) page or by contacting our customer
                support team for RocketGate/RGNative. A valid payment method,
                including credit card or PayPal, is required to process the
                payment for your Subscription. You shall provide us with
                accurate and complete billing information including full name,
                state, zip code, and a valid payment method information. By
                submitting such payment information, you automatically authorize
                us, to charge all Subscription fees incurred through your
                account to any such payment instruments. Access to our site and
                our media is restricted solely to the Subsciber and is expressly
                forbidden to anyone below the age of 18 years of age.
              </p>
            </div>
            <div className="box">
              <h2>Trial Memberships</h2>
              <p>
                Subscription as a trial for a limited period of time may be
                offered as an option. These memberships will recur at a monthly,
                multi-monthly, or yearly rate. We often only offer these trials
                exclusively on our Credit Card and PayPal join forms.
              </p>
            </div>
            <div className="box">
              <h2>Fee Changes</h2>
              <p>
                We, in our sole discretion and at any time, may modify the
                Subscription fees for the Subscriptions. Any Subscription fee
                change will become effective at the end of the then-current
                Billing Cycle. We will provide you with a reasonable prior
                notice of any change in Subscription fees to give you an
                opportunity to terminate your Subscription before such change
                becomes effective. Your continued use of the Service after the
                Subscription fee change comes into effect constitutes your
                agreement to pay the modified Subscription fee amount.
              </p>
            </div>
            <div className="box">
              <h2>Cancellation</h2>
              <p>
                We may terminate or suspend your account and bar access to the
                Service immediately, without prior notice or liability, under
                our sole discretion, for any reason whatsoever and without
                limitation, including but not limited to a breach of the Terms.
                If you wish to terminate your account, you must notify the
                Billing Support of the company which processed your order{" "}
                <a href="https://epoch.com/billingsupport">Epoch.com</a>,
                <a href="https://segpaycs.com/spsolo.aspx">SegPay.com</a>, or
                <a href="https://netvideogirls.com/support/">Ourselves</a> 48
                hours before your billing cycle ends. All provisions of the
                Terms which by their nature should survive termination shall
                survive termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnity and limitations of
                liability.
              </p>
            </div>
            <div className="box">
              <h2>Refunds</h2>
              <p>
                <strong>
                  Refunds or credits will not be issued for partially used
                  Memberships or partially used Access
                </strong>
                . The Subscriber may contact Billing Support for a refund, we
                reserve the right to grant or deny refunds at our own
                discretion. Chargeback All Chargebacks will result in the
                permanent ban of your email, credit card, and address in our
                system. All Chargebacks will result of an investigation of your
                personal information including, but not limited to the
                Subscriber's username, IP, and geolocation as logged by the
                Service. Contact us with any details that may help us unearth
                any fraudulent activity before requesting a Chargeback from your
                Credit Card company.
              </p>
            </div>
            <div className="box">
              <h2>Accounts</h2>
              <p>
                When you create an account with us, you guarantee that you are
                above the age of 18, and that the information you provide us is
                accurate, complete, and current at all times. Inaccurate,
                incomplete, or obsolete information may result in the immediate
                termination of your account on the Service. You are responsible
                for maintaining the confidentiality of your account and
                password, including but not limited to the restriction of access
                to your computer and/or account. You agree to accept
                responsibility for any and all activities or actions that occur
                under your account and/or password, whether your password is
                with our Service or a third-party service. You must notify us
                immediately upon becoming aware of any breach of security or
                unauthorized use of your account. You may not use as a username
                the name of another person or entity or that is not lawfully
                available for use, a name or trademark that is subject to any
                rights of another person or entity other than you, without
                appropriate authorization. You may not use as a username any
                name that is offensive, vulgar or obscene.
              </p>
            </div>
            <div className="box">
              <h2>Intellectual Property</h2>
              <p>
                The Service and its original content, features and functionality
                are and will remain Our exclusive property and its licensors.
                The Service is protected by copyright, trademark, and other laws
                of both the United States and foreign countries. Our trademarks
                and trade dress may not be used in connection with any product
                or service without the prior written consent of Our Company.
              </p>
            </div>
            <div className="box">
              <h2>Links To Other Web Sites</h2>
              <p>
                Our Service may contain links to third party web sites or
                services that we do not control or own. Our Company has no
                control over, and assumes no responsibility for the content,
                privacy policies, or practices of any third party web sites,
                applications, or services. We do not warrant the offerings of
                any of these entities/individuals or their websites. You
                acknowledge and agree that we shall not be responsible or
                liable, directly or indirectly, for any damage or loss caused or
                alleged to be caused by or in connection with use of or reliance
                on any such content, goods or services available on or through
                any such third party web sites or services. We strongly advise
                you to read the terms and conditions and privacy policies of any
                third party web sites or services that you visit.
              </p>
            </div>
            <div className="box">
              <h2>Indemnification</h2>
              <p>
                You agree to defend, indemnify and hold harmless Our Company and
                its licensee and licensors, and their employees, contractors,
                agents, officers and directors, from and against any and all
                claims, damages, obligations, losses, liabilities, costs or
                debt, and expenses (including but not limited to attorneyâfees),
                resulting from or arising out of a) your use and access of the
                Service, by you or any person using your account and password,
                or b) a breach of these Terms.
              </p>
            </div>
            <div className="box">
              <h2>Limitation Of Liability</h2>
              <p>
                In no event shall Our Company, nor its directors, employees,
                partners, agents, suppliers, or affiliates, be liable for any
                indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from (i)
                your access to or use of or inability to access or use the
                Service; (ii) any conduct or content of any third party on the
                Service; (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or
                content, whether based on warranty, contract, tort (including
                negligence) or any other legal theory, whether or not we have
                been informed of the possibility of such damage, and even if a
                remedy set forth herein is found to have failed of its essential
                purpose. Furthermore, the Service cannot support the security or
                stability of any Third Party Service used for communications
                between a performer and the Subscriber. Each performer uses the
                Service as a means to communicate with their fans. The
                communications and any media provided are beyond our purview and
                not an offering of the Service. We do our very best to insure
                that a Performer is indeed who they say they are. We do not
                perform security/background checks on the Performers and again
                stress that you should never provide any personal information to
                any of the Performers. We cannot and will not guarantee that all
                Perforers are who they say they are.
              </p>
            </div>
            <div className="box">
              <h2>Disclaimer</h2>
              <p>
                Your use of the Service is at your sole risk. The Service is
                provided on an "AS IS" and "AS AVAILABLE" basis. The Service is
                provided without warranties of any kind, whether express or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance. Our Company its
                subsidiaries, affiliates, and its licensors do not warrant that
                a) the Service will function uninterrupted, secure or available
                at any particular time or location; b) any errors or defects
                will be corrected; c) the Service is free of viruses or other
                harmful components; or d) the results of using the Service will
                meet your requirements.
              </p>
            </div>
            <div className="box">
              <h2>Governing Law</h2>
              <p>
                These Terms shall be governed and construed in accordance with
                the laws of California, United States, without regard to its
                conflict of law provisions. Our failure to enforce any right or
                provision of these Terms will not be considered a waiver of
                those rights. If any provision of these Terms is held to be
                invalid or unenforceable by a court, the remaining provisions of
                these Terms will remain in effect. These Terms constitute the
                entire agreement between us regarding our Service, and supersede
                and replace any prior agreements we might have had between us
                regarding the Service.
              </p>
            </div>
            <div className="box">
              <h2>Changes</h2>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will provide at least 30 days notice prior to any new terms
                taking effect. What constitutes a material change will be
                determined at our sole discretion. By continuing to access or
                use our Service after any revisions become effective, you agree
                to be bound by the revised terms. If you do not agree to the new
                terms, you are no longer authorized to use the Service.
              </p>
            </div>
            <div className="box">
              <h2>Contact Us</h2>
              <p>
                If you have any questions about these Terms, please{" "}
                <Link to="/contact">contact us</Link>.
              </p>
              <p>
                Last Updated: <strong>June 10th, 2019</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Terms
